function nfResponse() {
	// eslint-disable-next-line complexity
	$( document ).on( "nfFormSubmitResponse", function( event, { response } ) {
		event.preventDefault();
		const responsePopup = $( "#modal-response-popup" );
		const otherPopups = $( ".modal.show:not(#modal-success-popup)" );
		let resultMessage = null;
		let title = null;
		const defaultTitleMessage = {
			success: "Thank you",
			error: "Something went wrong!"
		};

		const responseData = response.data;
		// eslint-disable-next-line camelcase
		const { redirect, success_message } = responseData.actions;

		if ( !responsePopup.length || redirect ) {
			return;
		}

		const getErrorMessage = () => {
			const firstErrorKey = Object.keys( response.errors?.fields )[0];
			let errorMessage = response.errors?.fields[ firstErrorKey ];

			if ( typeof errorMessage === "object" ) {
				const fieldName = $( "#nf-label-field-" + firstErrorKey ).clone()
					.children()
					.remove()
					.end()
					.text()
					.trim();

				errorMessage = fieldName + ": " + errorMessage.message;
			}

			if ( typeof errorMessage === "string" ) {
				if ( errorMessage.includes( "CleanTalk" ) ) {
					errorMessage = responsePopup.find( ".text-content" ).attr( "data-cleantalk" );
				}
			}

			return errorMessage;
		};

		if ( Object.keys( response.errors ).length ) {
			// Error
			responsePopup.find( ".popup-title" ).addClass( "popup-title--error" );
			title = defaultTitleMessage.error;
			resultMessage = getErrorMessage();

			const formId = response.data.form_id;

			// Retrieve form fields model
			const formFields = nfRadio.channel( `form-${formId}` ).request( "get:form" ).get( "fields" );

			// Iterate through all fields and remove errors
			formFields.models.forEach( fieldModel => {
				if ( fieldModel.get( "errors" ).length ) {
					nfRadio.channel( "fields" ).request( "remove:error", fieldModel.get( "id" ), "required-error" );
				}
			} );
		} else {
			// Success
			const forms = $( ".nf-form-cont" );
			let hash = "#thank-you";

			const formId = responseData.form_id;
			const currentForm = forms.filter( `#nf-form-${formId}-cont` );

			const classList = Array.from( currentForm[0].classList );
			const matchingClass = classList.find( ( item ) => item.startsWith( "js-form_" ) );

			if ( matchingClass ) {
				hash += `-${matchingClass.split( "_" )[1]}`;
			}

			history.replaceState( null, document.title, location.pathname + location.search + hash );
			responsePopup.find( ".popup-title" ).removeClass( "popup-title--error" );
			const parsedMessage = parseResponse( success_message );
			title = parsedMessage.title ? parsedMessage.title : defaultTitleMessage.success;
			resultMessage = parsedMessage.message;
		}


		responsePopup.find( ".popup-title" ).html( title );
		responsePopup.find( ".popup-description .text-content p" ).html( resultMessage );
		if ( otherPopups.length ) {
			otherPopups.modal( "hide" );
			setTimeout( function() { responsePopup.modal( "show" ); }, 150 );
		} else {
			responsePopup.modal( "show" );
		}
	} );

	$( "#modal-response-popup" ).on( "hidden.bs.modal", function() {
		history.replaceState( null, document.title, location.pathname + location.search );
	} );
}

function parseResponse( response ) {
	const titleMatch = response.match( /<h[1-6]>(.*?)<\/h[1-6]>/ );
	const messageMatch = response.match( /<p>(.*?)<\/p>/ );

	const title = titleMatch ? titleMatch[1] : "";
	const message = messageMatch ? messageMatch[1] : "";

	return { title, message };
}

$( function() {
	nfResponse();
} );
