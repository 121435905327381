import YouTubePlayer from "youtube-player";

const initVideoPopups = ( () => {
	$( document ).ready( function() {
		const $modalVideo = $( "#modal-video" );
		const $video = $modalVideo.find( "video" );
		const $modalYoutubeVideo = $( "#modal-youtube-video" );
		const $player = $modalYoutubeVideo.find( ".js-modal-video-player" );
		const $btModalVideo = $( ".js-bt-modal-video" );
		const $btModalYoutubeVideo = $( ".js-bt-modal-youtube-video" );

		/*
		** Local Video Modal Logic
		*/
		if ( $btModalVideo.length && $modalVideo.length ) {
			$btModalVideo.off();

			$btModalVideo.on( "click", function() {
				const $target = $( this );
				const posterUrl = $target.data( "poster-url" );
				const videoUrl = $target.data( "video-url" );

				$video
					.attr( "src", videoUrl )
					.attr( "poster", posterUrl );

				$modalVideo.modal( "show" );

				$video.first().trigger( "play" );
			} );

			$modalVideo.on( "hide.bs.modal", function() {
				$video.first().trigger( "pause" );
			} );

			$( window ).on( "pause-all-videos", function() {
				$video.first().trigger( "pause" );
			} );
		}

		/*
		** Youtube Video Modal Logic
		*/
		if ( $btModalYoutubeVideo.length && $modalYoutubeVideo.length ) {
			const player = YouTubePlayer( $player[0] );

			$btModalYoutubeVideo.off();

			$btModalYoutubeVideo.on( "click", function() {
				const $target = $( this );
				const videoId = $target.data( "video-id" );

				player.loadVideoById( videoId );

				$modalYoutubeVideo.modal( "show" );

				player.playVideo();
			} );

			$modalYoutubeVideo.on( "hide.bs.modal", function() {
				player.stopVideo();
			} );

			$( window ).on( "pause-all-videos", function() {
				player.pauseVideo();
			} );
		}
	} );
} )();

$( function() {
	initVideoPopups;
} );

