const initScrollToTargetButtons = ( () => {
	$( document ).ready( function() {
		const $scrollButtons = $( "[data-scroll-target]" );

		$scrollButtons.each( ( i, button ) => {
			const $button = $( button );
			const tragetId = $button.data( "scrollTarget" );
			const $target = $( `#${tragetId}` );

			if ( !$target.length ) {return;}

			$button.on( "click", ( e ) => {
				e.preventDefault();
				let scrollHeight = $target.first().offset().top;
				let headerHeight = 0;
				let adminbarHeight = 0;

				if ( $( ".section-header" ).length ) {
					headerHeight = $( ".section-header" ).outerHeight();
				}

				if ( $( "#wpadminbar" ).length ) {
					adminbarHeight = $( "#wpadminbar" ).outerHeight();
				}

				let resultScrollHeight = scrollHeight - headerHeight - adminbarHeight - 30;

				window.scrollTo( {top: resultScrollHeight} );
			} );
		} );
	} );
} )();

$( function() {
	initScrollToTargetButtons;
} );
