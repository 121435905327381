function residentialForm() {
	const AJAX_URL = window.theme_vars.ajax_url;
	const residentialFormId = window.theme_vars.residential_form_id;
	const deliveryDaysOffDates = window.theme_vars.delivery_days_off;
	const formatter = new Intl.NumberFormat( "en-US", {
		style: "decimal",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	} );
	let dropOffDateValue = null;

	const getMainFormFields = ( formChannel ) => {
		return {
			zipCode: formChannel.request( "get:fieldByKey", "zip_code" ),
			typeOfWasteDebris: formChannel.request( "get:fieldByKey", "type_of_waste_debris" ),
			sizeOfDumpster: formChannel.request( "get:fieldByKey", "size_of_dumpster" ),
			dropOffDate: formChannel.request( "get:fieldByKey", "drop_off_date" ),
			pickUpDate: formChannel.request( "get:fieldByKey", "pick_up_date" ),
			numberOfMattresses: formChannel.request( "get:fieldByKey", "number_of_mattresses" ),
			numberOfBoxSprings: formChannel.request( "get:fieldByKey", "number_of_box_springs" ),
			numberOfTires: formChannel.request( "get:fieldByKey", "number_of_tires" ),
			discountCode: formChannel.request( "get:fieldByKey", "discount_code" ),
			agreement: formChannel.request( "get:fieldByKey", "agreement" ),
			total: formChannel.request( "get:fieldByKey", "total" ),
			originalTotal: formChannel.request( "get:fieldByKey", "original_total" ),
			paymentToken: formChannel.request( "get:fieldByKey", "payment_token" ),
			fifteenYardImage: formChannel.request( "get:fieldByKey", "15-yard-image" ),
			twentyYardImage: formChannel.request( "get:fieldByKey", "20-yard-image" ),
			thirtyYardImage: formChannel.request( "get:fieldByKey", "30-yard-image" ),
			fourtyYardImage: formChannel.request( "get:fieldByKey", "40-yard-image" ),
		};
	};
	const toggleButton = ( selector, disable = true ) => {
		const button = $( selector );
		if ( disable ) {
			button.attr( "disabled", true );
			button.find( ".button-text" ).text( button.data( "loadingText" ) );
		} else {
			button.removeAttr( "disabled" );
			button.find( ".button-text" ).text( button.data( "defaultText" ) );
		}
	};
	const addFieldError = ( fieldModel, error ) => {
		fieldModel.get( "errors" ).add( error );
		fieldModel.trigger( "change:errors", fieldModel );
	};
	const removeFieldError = ( fieldModel, errorId ) => {
		nfRadio.channel( "fields" ).request( "remove:error", fieldModel.get( "id" ), errorId );
	};
	const getFlatpickrInstance = ( fieldModel ) => {
		const el = $( fieldModel.get( "el" ) ).find( ".ninja-forms-field.datepicker" );
		return el[0]._flatpickr;
	};
	const moveDatePicker = ( flatpickr, wrapper ) => {
		if ( !flatpickr ) { return; }

		const calendarContainer = flatpickr.calendarContainer;

		flatpickr.config.onOpen.push( function() {
			calendarContainer.style.translate = `0 ${ wrapper.scrollTop }px`;
		} );

		calendarContainer.style.translate = `0 ${ wrapper.scrollTop }px`;
		wrapper.append( calendarContainer );
	};
	const configureDatePicker = ( fieldModel ) => {
		const flatpickr = getFlatpickrInstance( fieldModel );
		if ( !flatpickr ) { return; }

		const fieldKey = fieldModel.get( "key" );
		if ( fieldKey === "drop_off_date" ) {
			flatpickr.set( "disable", [
				function( date ) {
					return (
						date - new Date() <= 0 ||
						date.getDay() === 0 ||
						date.getDay() === 6
					);
				}, ...deliveryDaysOffDates
			] );
		} else if ( fieldKey === "pick_up_date" ) {
			flatpickr.set( "disable", [
				function( date ) {
					return (
						dropOffDateValue && date - new Date( dropOffDateValue ) <= 0 ||
						date - new Date() <= 0 ||
						date.getDay() === 0 ||
						date.getDay() === 6
					);
				}, ...deliveryDaysOffDates
			] );
		}

		moveDatePicker( flatpickr, $( "#modal-residental" )[0] );
	};
	const toggleFormNav = ( formId, disable = true ) => {
		const nav = $( `#nf-form-${formId}-cont .nf-previous, #nf-form-${formId}-cont .nf-next` );
		if ( disable ) {
			nav.attr( "disabled", true );
		} else {
			nav.removeAttr( "disabled" );
		}
	};

	customElements.define( "card-area", class CardArea extends HTMLElement {
		constructor() {
			super();

			this._cardFieldsStatus = {
				ccnumber: false,
				ccexp: false,
				cvv: false,
			};
			this._callback = this._callback.bind( this );
			this._validationCallback = this._validationCallback.bind( this );
		}

		connectedCallback() {
			// CollectJS NOT official doc https://secure.apsmerchantgateway.com/merchants/resources/integration/integration_portal.php#cjs_example_inline2_js
			window.CollectJS.configure( {
				"callback" : this._callback,
				"validationCallback": this._validationCallback,
			} );
		}

		_callback( response ) {
			this.dispatchEvent( new CustomEvent( "cardareasubmit", {
				detail: { response },
				bubbles: true,
			} ) );
		}

		_validationCallback( field, status ) {
			this._cardFieldsStatus[field] = status;
			this.dispatchEvent( new CustomEvent( "cardareavalidate", {
				detail: {
					success: this.isCardsDataFullfilled(),
				},
				bubbles: true,
			} ) );
		}

		isCardsDataFullfilled() {
			return this._cardFieldsStatus.ccnumber && this._cardFieldsStatus.ccexp && this._cardFieldsStatus.cvv;
		}

		startPaymentRequest() {
			window.CollectJS.startPaymentRequest();
		}
	} );

	if ( typeof Marionette !== "undefined" ) {
		const customController = Marionette.Object.extend( {
			initialize: function() {
				/* eslint-disable complexity */
				this.listenTo( nfRadio.channel( "form" ), "render:view", function( event ) {
					const formModel = event.model;
					const formId = formModel.get( "id" );
					if ( formId !== residentialFormId ) {
						return;
					}

					const formChannel = nfRadio.channel( "form-" + formModel.get( "id" ) );

					const {
						zipCode,
						typeOfWasteDebris,
						sizeOfDumpster,
						fifteenYardImage,
						twentyYardImage,
						thirtyYardImage,
						fourtyYardImage,
						dropOffDate,
						pickUpDate,
						numberOfMattresses,
						numberOfBoxSprings,
						numberOfTires,
						discountCode,
						agreement,
						paymentToken,
					} = getMainFormFields( formChannel );

					if (
						!zipCode ||
						!typeOfWasteDebris ||
						!sizeOfDumpster ||
						!fifteenYardImage ||
						!twentyYardImage ||
						!thirtyYardImage ||
						!fourtyYardImage ||
						!dropOffDate ||
						!pickUpDate ||
						!numberOfMattresses ||
						!numberOfBoxSprings ||
						!numberOfTires ||
						!discountCode ||
						!agreement ||
						!paymentToken
					) {
						return;
					}

					// zip code validation
					zipCode.on( "change:value", ( model ) => {
						toggleFormNav( formId );

						$.ajax( `${AJAX_URL}v1/check-zip-code/`, {
							method: "POST",
							data: {
								"zip_code": model.get( "value" ),
							},
							success( data ) {
								if ( data.success ) {
									removeFieldError( zipCode, "invalid-zip-code" );
								} else {
									addFieldError( zipCode, {
										id: "invalid-zip-code",
										msg: data.zip_code,
									} );
								}
							},
							complete() {
								toggleFormNav( formId, false );
							}
						} );
					} );

					// slide down of dumpster previews
					[fifteenYardImage, twentyYardImage, thirtyYardImage, fourtyYardImage].forEach( item => {
						item.on( "change:visible", ( model ) => {
							if ( model.get( "visible" ) && !sizeOfDumpster.previous( "value" ) ) {
								const fieldContainer = $( `#nf-field-${model.get( "id" )}-container` );
								fieldContainer.hide().slideDown();
							}
						} );
					} );

					dropOffDate.on( "change:value", ( model ) => {
						dropOffDateValue = model.get( "value" );
						const pickUpDateValue = pickUpDate.get( "value" );

						if (
							pickUpDateValue &&
							new Date( pickUpDateValue ) - new Date( dropOffDateValue ) <= 0
						) {
							pickUpDate.set( "value", "" );
						}

						pickUpDate.trigger( "reRender", pickUpDate );
					} );

					pickUpDate.on( "reRender", () => {
						setTimeout( () => {
							configureDatePicker( pickUpDate );
						}, 0 );
					} );

					// validation of numeric fields
					[
						{
							fieldModel: numberOfMattresses,
							error: {
								id: "invalid-number-of-mattresses",
								msg: "Please enter a valid number of mattresses.",
							}
						},
						{
							fieldModel: numberOfBoxSprings,
							error: {
								id: "invalid-number-of-box-springs",
								msg: "Please enter a valid number of tires number of box springs.",
							}
						},
						{
							fieldModel: numberOfTires,
							error: {
								id: "invalid-number-of-number-of-tires",
								msg: "Please enter a valid number of tires number of tires.",
							}
						}
					].forEach( ( { fieldModel, error } ) => {
						fieldModel.on( "change:value", ( model ) => {
							const value = Number( model.get( "value" ) );
							if ( Number.isInteger( value ) && value >= 0 ) {
								removeFieldError( model, error.id );
							} else {
								addFieldError( model, error );
							}
						} );
					} );

					discountCode.on( "change:value", ( model ) => {
						const value = model.get( "value" );
						if ( !value ) {
							removeFieldError( model, "invalid-discount-code" );
							return;
						}

						toggleFormNav( formId );

						$.ajax( `${AJAX_URL}v1/check-discount-code/`, {
							method: "POST",
							data: {
								"discount_code": value,
							},
							success( data ) {
								if ( data.success ) {
									removeFieldError( model, "invalid-discount-code" );

									model.set( "classes", `${ model.get( "classes" ) } ninja-forms-field--green` );
									model.trigger( "reRender", model );
								} else {
									addFieldError( model, {
										id: "invalid-discount-code",
										msg: "Invalid discount code",
									} );

									model.set( "classes", model.get( "classes" ).replace( " ninja-forms-field--green", "" ) );
									model.trigger( "reRender", model );
								}
							},
							complete() {
								toggleFormNav( formId, false );
							}
						} );
					} );

					agreement.on( "change:value", ( model ) => {
						const agreementValue = model.get( "value" );
						if ( agreementValue === "No" ) {
							addFieldError( model, {
								id: "invalid-agreement",
								msg: "You must agree to the terms and conditions before placing an order.",
							} );
						} else {
							removeFieldError( model, "invalid-agreement" );
						}
					} );

					event.$el.on( "click", function( event ) {
						const target = $( event.target );

						if ( target.closest( "#residential-fake-submit" ).length ) {
							const cardArea = $( "#residential-card-area" )[0];
							if ( !cardArea ) {
								return;
							}

							if ( cardArea.isCardsDataFullfilled() ) {
								toggleButton( "#residential-fake-submit", true );
								cardArea.startPaymentRequest();
							} else {
								addFieldError( paymentToken, {
									id: "invalid-card-data",
									msg: "Please fill in your payment card details first.",
								} );
							}
						}
					} );

					event.$el.on( "cardareavalidate", function( { detail } ) {
						if ( detail.success ) {
							removeFieldError( paymentToken, "invalid-card-data" );
						} else {
							addFieldError( paymentToken, {
								id: "invalid-card-data",
								msg: "Please fill in your payment card details first.",
							} );
						}
					} );

					event.$el.on( "cardareasubmit", function( { detail } ) {
						console.log( "payment response", detail.response );

						const token = detail.response?.token;
						if ( token ) {
							paymentToken.set( "value", token );
							paymentToken.trigger( "reRender", paymentToken );
							formChannel.request( "submit", formModel );
						} else {
							toggleButton( "#residential-fake-submit", false );
						}
					} );
				} );
				/* eslint-enable complexity */

				this.listenTo( nfRadio.channel( `form-${residentialFormId}` ), "before:submit", function() {
					toggleButton( "#residential-fake-submit", true );
				} );

				this.listenTo( nfRadio.channel( `form-${residentialFormId}` ), "submit:response", function( response ) {
					toggleButton( "#residential-fake-submit", false );
					const responseData = response.data;
					if ( response.errors.length ) {return;}
					const formId = responseData.form_id;
					const formModel = nfRadio.channel( `form-${formId}` ).request( "get:form" );
					const formContentData = formModel.get( "formContentData" );

					if ( formContentData.length > 1 ) {
						formContentData.currentElement = formContentData.getVisibleParts()[0];
						formContentData.trigger( "change:part", formContentData );
						nfRadio.channel( "nfMP" ).trigger( "change:part", formContentData );
					}
				} );

				/* eslint-disable complexity */
				this.listenTo( nfRadio.channel( "nfMP" ), "change:part", function( model ) {
					const formModel = model.formModel;
					if ( formModel.get( "id" ) !== residentialFormId ) {
						return;
					}

					const formContentData = formModel.get( "formContentData" );
					const currentElementOrder = formContentData.currentElement.get( "order" );
					const formChannel = nfRadio.channel( "form-" + formModel.get( "id" ) );
					const {
						zipCode,
						typeOfWasteDebris,
						sizeOfDumpster,
						dropOffDate,
						pickUpDate,
						numberOfMattresses,
						numberOfBoxSprings,
						numberOfTires,
						discountCode,
						total,
						originalTotal,
						paymentToken,
					} = getMainFormFields( formChannel );

					if (
						!zipCode ||
						!typeOfWasteDebris ||
						!sizeOfDumpster ||
						!dropOffDate ||
						!pickUpDate ||
						!numberOfMattresses ||
						!numberOfBoxSprings ||
						!numberOfTires ||
						!discountCode ||
						!total ||
						!originalTotal ||
						!paymentToken
					) {
						return;
					}

					let prices = null;

					const setLabelsHelper = ( fieldModel, regex, newSubstr ) => {
						const label = fieldModel.get( "label" );
						fieldModel.set( "label", label.replace( regex, `(${newSubstr})` ) );
						fieldModel.set( "placeholder", label.replace( regex, `(${newSubstr})` ) );
						fieldModel.trigger( "reRender", fieldModel );
					};

					const setLabels = () => {
						const regex = /\([^)]*\)/;
						setLabelsHelper( numberOfMattresses, regex, `${ formatter.format( prices?.mattresses || 0 ) } per` );
						setLabelsHelper( numberOfBoxSprings, regex, `${ formatter.format( prices?.box_springs || 0 ) } per` );
						setLabelsHelper( numberOfTires, regex, `${ formatter.format( prices?.tires || 0 ) } per` );
					};

					if ( currentElementOrder === 1 ) {
						configureDatePicker( dropOffDate );
						configureDatePicker( pickUpDate );

						$.ajax( `${AJAX_URL}v1/calculate-item-prices/`, {
							method: "POST",
							data: {
								"zip_code": zipCode.get( "value" ),
								"type_of_waste_debris": typeOfWasteDebris.get( "value" ),
								"size_of_dumpster": sizeOfDumpster.get( "value" ),
							},
							success( data ) {
								prices = data.prices;
								setLabels();
							}
						} );
					} else if ( currentElementOrder === 2 ) {
						$.ajax( `${AJAX_URL}v1/calculate-total-price/`, {
							method: "POST",
							data: {
								"zip_code": zipCode.get( "value" ),
								"type_of_waste_debris": typeOfWasteDebris.get( "value" ),
								"size_of_dumpster": sizeOfDumpster.get( "value" ),
								"drop_off_date": dropOffDate.get( "value" ),
								"pick_up_date": pickUpDate.get( "value" ),
								"mattresses": parseInt( numberOfMattresses.get( "value" ) ) || 0,
								"box_springs": parseInt( numberOfBoxSprings.get( "value" ) ) || 0,
								"tires": parseInt( numberOfTires.get( "value" ) ) || 0,
								"discount_code": discountCode.get( "value" ),
							},
							success( data ) {
								let originalTotalValue = "";
								if ( data.is_discount ) {
									originalTotalValue = formatter.format( data.price_without_discount );
								}

								total.set( "value", formatter.format( data.total_price ) );
								total.trigger( "reRender", total );

								originalTotal.set( "value", originalTotalValue );
								originalTotal.trigger( "reRender", total );
							}
						} );
					}
				} );
				/* eslint-enable complexity */
			},
		} );

		// initialise listening controller for ninja form
		new customController();
	}
}

$( function() {
	residentialForm();
} );
